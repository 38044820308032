<template>
  <div class="main">
    <div style="max-width:1560px;margin:0 auto">
      <el-carousel height="675px" width="1200px">
        <el-carousel-item v-for="item in banners" :key="item">
            <img :src="item" alt="" class="carouselItem">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="container">
      <p style="margin-top:10px">
        <router-link class="position" :to="{name:'index',params:{index:0}}">首页 &gt;&gt;</router-link>
        <router-link class="position" :to="{name:'products',params:{index:3}}"> 产品展示 <span v-if="currentname">&gt;&gt;</span></router-link>
        <span class="position"> {{currentname}}</span>
        
      </p>
      <div class="productscontainer">
        <div class="productscontainerL">
          <div class="productscontainerL1">产品展示</div>
          <div @click="typechange(item)" :class="currenttype==item.type?'productscontainerL2':'productscontainerL2 pro'" v-for="(item,index) in typelist" :key="index">{{item.name}}</div>
        </div>
        <div class="productscontainerR" v-if="currenttype===0">
          <router-link :to="{path:'/productsinfo',query:{img:item.op}}" class="productitem" v-for="(item,index) in list" :key=index>
            <img :src="item.img" alt="">
            <p>{{item.name}}</p>
          </router-link>
        </div>
        <div class="productscontainerR" v-else>
          <router-link :to="{path:'/productsinfo',query:{img:item.op}}" v-if="currenttype==item.type" class="productitem" v-for="(item,index) in list" :key=index>
            <img :src="item.img" alt="">
            <p>{{item.name}}</p>
          </router-link>
        </div>
        <!-- <div class="pageclass">
          <el-pagination
            current-page.sync="1"
            background
            prev-text="上一页"
            next-text="下一页"
            :page-size="20"
            layout="total, prev, pager, next"
            :total="10">
          </el-pagination>
        </div> -->
        
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'products',
  components: {
  },
  data(){
    return{
      banners:[
        'http://www.szxcd.com.cn/content/img/banner1.jpg',
        'http://www.szxcd.com.cn/content/img/banner2.jpg',
        'http://www.szxcd.com.cn/content/img/banner3.jpg',
        'http://www.szxcd.com.cn/content/img/banner4.jpg',
      ],
      currentname:'',
      currenttype:0,
      typelist:[
        {
          type:1,
          name:'堆垛机系列'
        },
        {
          type:2,
          name:'货叉系列'
        },
        {
          type:3,
          name:'公司案例'
        }
      ],
      list: [
  {
    img: 'http://www.szxcd.com.cn/content/img/p1.jpg',
    op: 'i1.jpg',
    name: 'XCD-GS',
    parentname: '堆垛机系列',
    type: 1,
    id: 1
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/p2.jpg',
    op: 'i2.jpg',
    name: 'XCD-GS',
    parentname: '堆垛机系列',
    type: 1,
    id: 2
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/p3.jpg',
    op: 'i3.jpg',
    name: 'XCD-SH',
    parentname: '堆垛机系列',
    type: 1,
    id: 3
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/p4.jpg',
    op: 'i4.jpg',
    name: 'XCD-SL',
    parentname: '堆垛机系列',
    type: 1,
    id: 4
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/h1.jpg',
    op: 'i5.jpg',
    name: 'LD-D-1Z',
    parentname: '货叉系列',
    type: 2,
    id: 5
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/h2.jpg',
    op: 'i6.jpg',
    name: 'LD-D-1Z',
    parentname: '货叉系列',
    type: 2,
    id: 6
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/h3.jpg',
    op: 'i7.jpg',
    name: 'LD-D-1Z',
    parentname: '货叉系列',
    type: 2,
    id: 7
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/h4.jpg',
    op: 'i8.jpg',
    name: 'LD-D-1Z',
    parentname: '货叉系列',
    type: 2,
    id: 8
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/h5.jpg',
    op: 'i9.jpg',
    name: 'LD-D-2Z',
    parentname: '货叉系列',
    type: 2,
    id: 9
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/h6.jpg',
    op: 'i10.jpg',
    name: 'LD-D-2Z',
    parentname: '货叉系列',
    type: 2,
    id: 10
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/h7.jpg',
    op: 'i11.jpg',
    name: 'LD-D-1Z',
    parentname: '货叉系列',
    type: 2,
    id: 11
  },
  {
    img: 'http://www.szxcd.com.cn/content/img/h8.jpg',
    op: 'i12.jpg',
    name: 'LD-D-1Z',
    parentname: '货叉系列',
    type: 2,
    id: 12
  },
]
    }
  },
  created () {
    window.scrollTo(0,0)
  },
  methods: {
    typechange(item){
      this.currentname=item.name
      this.$router.push({ 
          path: '/products',
          query:{//参数
              type:item.type,
          }
        }).catch(err=>err);
    }
  },
  watch: {
      //监听路由地址的改变
      '$route.query':{
        handler(newval){
          if(JSON.stringify(newval)=='{}'){
            this.currentname='';
            this.currenttype=0
          }else{
            let list=this.typelist;
            for(let i=0;i<list.length;i++){
              if(list[i].type==newval.type){
                this.currentname=list[i].name
                this.currenttype=list[i].type
              }
            }
          }
        },
        immediate:true
      }
  },
}
</script>

<style scoped>
::v-deep .el-pagination .btn-next{
  padding: 0 10px;
}
::v-deep .el-pagination .btn-prev{
  padding: 0 10px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #fad749;
}
.pageclass{
  width: 910px;
  margin-left: 290px;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
}
.productscontainerR .productitem:nth-child(4n){
  margin-right: 0;
}
.productitem img{
  width: 210px;
  height: 210px;
  object-fit: cover;
}
.productitem p{
  line-height: 43px;
  font-size: 16px;
  color: #444;
  text-align: center;
}
.productitem:hover p{
  color:#fad749;
  transition: all .5s;
}
.productitem{
  font-size: 0;
  width: 210px;
  height: 253px;
  border: 1px solid #ccc; 
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all .5s;
}
.productscontainerL2{
  cursor: pointer;
  line-height: 50px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  /* background-color: #70b9ec; */
  background-image: url("http://www.szxcd.com.cn/content/img/button.png");
  background-size: 100% 100%;
  margin-bottom: 1px;
  opacity: 1;
}
.pro{
  opacity: .7;
}
.productscontainerL1{
  line-height: 80px;
  font-size: 30px;
  background-color: #154ec3;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}
.productscontainerL{
  width: 270px;
}
.productscontainerR{
  display: flex;
  flex-wrap: wrap;
  width: 910px;
}
.productscontainer{
  display: flex;
  margin-top: 34px;
  justify-content: space-between;
  flex-wrap: wrap;
}
a{
  text-decoration: none;
  font-size: 14px;
  color: #666;
}
.carouselItem{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.active{
  background-color: #ffd800;
}
.position{
  font-size: 14px;
  color: #666;
}
</style>
