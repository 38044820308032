<template>
  <div class="main">
    <div style="max-width:1560px;margin:0 auto">
      <el-carousel height="630px">
        <el-carousel-item v-for="item in 4" :key="item">
          <img src="http://www.szxcd.com.cn/content/img/banner.jpeg" alt="" class="carouselItem">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="container">
      <p style="margin-top:10px">
        <router-link class="position" :to="{name:'index',params:{index:0}}">首页 &gt;&gt;</router-link>
        <router-link class="position" :to="{name:'products',params:{index:3}}"> 客户案例 <span v-if="currentname">&gt;&gt;</span></router-link>
        <!-- <span class="position"> {{currentname}}</span> -->
        
      </p>
      <div class="productscontainer">
        <div class="productscontainerL">
          <div class="productscontainerL1">客户案例</div>
          <div @click="currenttype=item.type;currentname=item.name" :class="currenttype==item.type?'productscontainerL2 active':'productscontainerL2'" v-for="(item,index) in typelist" :key="index">{{item.name}}</div>
        </div>
        <div class="productscontainerR">
          <div @click="tocaseinfo(item)" class="productitem" v-for="(item,index) in list" :key=index>
            <img :src="item.img" alt="">
            <p>{{item.name}}</p>
          </div>
        </div>
        <div class="pageclass">
          <el-pagination
            current-page.sync="1"
            background
            prev-text="上一页"
            next-text="下一页"
            :page-size="20"
            layout="total, prev, pager, next"
            :total="10">
          </el-pagination>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'cases',
  components: {
  },
  data(){
    return{
      currentname:'',
      currenttype:0,
      typelist:[
        {
          type:1,
          name:'客户案例'
        },
        
      ],
      list:[
        {
          img:'http://www.szxcd.com.cn/content/img/banner.jpeg',
          name:'系列1',
          parentname:'A系列',
          type:1
        },
        {
          img:'http://www.szxcd.com.cn/content/img/banner.jpeg',
          name:'系列2',
          parentname:'B系列',
          type:2
        },
        {
          img:'http://www.szxcd.com.cn/content/img/banner.jpeg',
          name:'系列1',
          parentname:'A系列',
          type:1
        },
        {
          img:'http://www.szxcd.com.cn/content/img/banner.jpeg',
          name:'系列2',
          parentname:'B系列',
          type:2
        },
        {
          img:'http://www.szxcd.com.cn/content/img/banner.jpeg',
          name:'系列1',
          parentname:'A系列',
          type:1
        },
        {
          img:'http://www.szxcd.com.cn/content/img/banner.jpeg',
          name:'系列2',
          parentname:'B系列',
          type:2
        }
      ]
    }
  },
  methods:{
    tocaseinfo(item){
      console.log(item);
      this.$router.push({path:'/caseinfo',query:{parentname:item.parentname,name:item.name}})
    }
  },
  created () {
    window.scrollTo(0,0)
  },
  watch: {
      //监听路由地址的改变
      '$route.query':{
        handler(newval){
          if(JSON.stringify(newval)=='{}'){
            this.currentname='';
            this.currenttype=0
          }else{
            let list=this.typelist;
            for(let i=0;i<list.length;i++){
              if(list[i].type==newval.type){
                this.currentname=list[i].name
                this.currenttype=list[i].type
              }
            }
          }
        },
        immediate:true
      }
  },
}
</script>

<style scoped>
::v-deep .el-pagination .btn-next{
  padding: 0 10px;
}
::v-deep .el-pagination .btn-prev{
  padding: 0 10px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #fad749;
}
.pageclass{
  width: 910px;
  margin-left: 290px;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
}
.productscontainerR .productitem:nth-child(3n){
  margin-right: 0;
}
.productitem img{
  width: 210px;
  height: 210px;
  object-fit: cover;
  display: block;
  margin: 35px auto;
}
.productitem p{
  line-height: 54px;
  font-size: 16px;
  color: #444;
  text-align: center;
}
.productitem:hover p{
  color:#fad749;
  transition: all .5s;
}
.productitem{
  font-size: 0;
  width: 300px;
  height: 334px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all .5s;
}
.productscontainerL2{
  cursor: pointer;
  line-height: 50px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  background-color: #70b9ec;
  margin-bottom: 1px;
}
.productscontainerL1{
  line-height: 80px;
  font-size: 30px;
  background-color: #000;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}
.productscontainerL{
  width: 270px;
}
.productscontainerR{
  display: flex;
  flex-wrap: wrap;
  width: 910px;
}
.productscontainer{
  display: flex;
  margin-top: 34px;
  justify-content: space-between;
  flex-wrap: wrap;
}
a{
  text-decoration: none;
  font-size: 14px;
  color: #666;
}
.carouselItem{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active{
  background-color: #ffd800;
}
.position{
  font-size: 14px;
  color: #666;
}
</style>
